<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-woman-nature-2">
            <div class="container mx-auto">
                <div class="p-20 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-lightgreen text-xl font-black font-epigrafica mt-2 uppercase">Contactez-nous</h1>
                        <p class="text-white">Avez-vous des questions ? Nous serions ravis de vous répondre. <br> remplissez le formulaire ci-dessous ou référez-vous à nos coordonnées...</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- CONTACT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="container mx-auto">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <p class="text-2xl text-mineralgreen font-black font-epigrafica my-4">Nos Coordonnées</p>
                                <p class="text-mineralgreen text-base text-justify font-medium mb-5">
                                    > <b>Siège social :</b> Abidjan, Cocody 2 plateaux 9e Tranche <br>
                                    > <b>Tél. :</b> (225) 05 76 23 22 22 <br>
                                    > <b>Adresse :</b> BP 385 Aboisso <br>
                                    > <b>Email :</b> contact@ibiocosmetics.com <br>
                                </p>
                                
                                <h1 class="text-mineralgreen-dark text-xl font-black font-epigrafica mt-2 uppercase">Suivez-nous aussi sur :</h1>
                                <div class="col-12 flex flex-row mt-2">
                                    <q-btn color="mineralgreen" icon="mdi-facebook" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                                    <q-btn color="mineralgreen" icon="mdi-instagram" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                                    <q-btn color="mineralgreen" icon="mdi-twitter" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                                    <q-btn color="mineralgreen" icon="mdi-linkedin" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 p-5">
                                <p class="text-2xl text-mineralgreen font-black font-epigrafica my-4">Laissez-nous un message   </p>
                                <form>
                                    <div class="row q-gutter-md">
                                        <div class="col-12 col-sm-12 col-md-12">
                                            <input type="text" v-model="form.fullname" placeholder="Saissisez votre Nom et prénoms" class="form-input border-mineralgreen-dark focus:border-mineralgreen-dark rounded-md text-mineralgreen w-full mr-4 focus:ring-0" />
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12">
                                            <input type="email" required v-model="form.email" placeholder="Saissisez votre adresse email*" class="form-input border-mineralgreen-dark focus:border-mineralgreen-dark rounded-md text-mineralgreen w-full mr-4 focus:ring-0" />
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12">
                                            <input type="text" v-model="form.subject" placeholder="Saissisez le sujet du message" class="form-input border-mineralgreen-dark focus:border-mineralgreen-dark rounded-md text-mineralgreen w-full mr-4 focus:ring-0" />
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12">
                                            <textarea v-model="form.message" required placeholder="Saissisez votre message*" cols="30" rows="10" class="form-input border-mineralgreen-dark focus:border-mineralgreen-dark rounded-md text-mineralgreen w-full mr-4 focus:ring-0"></textarea>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 text-mineralgreen">
                                            <input type="checkbox" v-model="form.captcha" required class="form-input border-mineralgreen-dark focus:border-mineralgreen-dark text-mineralgreen mr-4 focus:ring-0" /> Je ne suis pas un robot
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12">
                                            <q-btn @click.prevent="send" :loading="loader" :disabled="v$.form.$invalid" type="sumbit" class="bg-mineralgreen-dark hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-md w-50 mr-4">Send Message</q-btn>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"
import useValidate from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import Mailer from "@/app/services/mailer.service"
import { Notify } from "quasar"
import Message from "@/app/config/message"

export default {
    name: "Contact",
    components: { navbar, footerbar },
    data() {
        return {
            v$: useValidate(),
            form: {
                fullname: null,
                email: null,
                subject: null,
                message: null,
                captcha: false,
            },
            loader: false,
        }
    },
    validations() { // validation form
        return {
            form: {
                fullname: { required },
                email: { required, email },
                subject: { required },
                message: { required },
                captcha: { required },
            },
        }
    },
    methods: {
        send () {
            this.loader = true
            Mailer
                .message(this.form)
                .then((response) => {
                    if (response.data) {
                        this.loader = false
                        return Notify.create(response.data.message)
                    }
                    this.loader = false
                    Notify.create(Message.notsent)
                })
                .catch((error) => {
                    if (error.response.data) {
                        Notify.create(Message.failed)
                        this.loader = false
                        return Notify.create(error.response.data.message)
                    }
                    this.loader = false
                    Notify.create(Message.error)
                })
        }
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Contactez-nous', // sets document title
        })
    },
};
</script>